import { memo, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import { FormattedDate, FormattedMessage } from "react-intl";
import "./DateCalendarInput.scss";
import classNames from "classnames";
import AppGlobalsContext from "app/AppGlobalsContext";
import { sendTagOnPriceCalendarDateClicked } from "app/utils/analytics";
import Calendar from "react-calendar";
import isSameDay from "date-fns/isSameDay";

import {
	autoUpdate,
	FloatingPortal,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useFocus,
	useInteractions,
} from "@floating-ui/react";
import IconCloseFull from "app/pages/.shared/IconCloseFull";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import IconArrowLeftCalendar from "app/pages/.shared/static/icons/IconArrowLeftCalendar";
import IconArrowRightCalendar from "app/pages/.shared/static/icons/IconArrowRightCalendar";
import Button from "app/pages/.shared/form/Button";
import IconCalendarFlightTakeOff from "app/pages/.shared/static/icons/IconCalendarFlightTakeOff";
import { DATE_INPUT_TYPE } from "app/constants";

// pour les tests e2e
const getTileClassname = ({ date }) => {
	return `date-calendar-input-tile__tile date-calendar-input-tile__tile--${date.getMonth() +
		1}-${date.getDate()}`;
};

const DateCalendarInput = ({
	id,
	className,
	departureDateMin,
	departureDateMax,
	checkDateAvailability = () => {},
	isCalendarDisabled,
	popperOffset = [],
	calendarDisabledView,
	updateFloatingPositionReference = {},
	loading,
	departureDateRef = {},
	endDateRef = {},
	popoverWidth = 1024,
	"data-testid": dataTestId,
	name,
}) => {
	const { shop } = useContext(AppGlobalsContext);
	const initialState = {
		isDateClicked: {
			departure: false,
			end: false,
		},
		isDateCleaned: {
			departure: false,
			end: false,
		},
		calendarKey: 0,
		startDate: new Date(),
	};
	const [state, setState] = useState(initialState);

	const [departureField, departureMeta, departureHelpers] = useField(`${name}.departureDate`);
	const [endField, endMeta, endHelpers] = useField(`${name}.endDate`);

	const [open, setOpen] = useState(false);

	const { isSubmitting } = useFormikContext();

	useEffect(() => {
		if (departureDateMin) {
			setState(prevState => ({
				...prevState,
				startDate: new Date(departureDateMin),
			}));
		}
	}, [departureDateMin]);

	const handleDateSelect = useCallback(
		date => {
			// if departure date is not selected
			if (date[0] && !departureField?.value) {
				departureHelpers.setValue(date[0]);
				departureHelpers.setTouched(false);
				setState(prevState => ({
					...prevState,
					isDateClicked: {
						...prevState.isDateClicked,
						departure: false,
					},
				}));
				endHelpers.setTouched(true);
				endHelpers.setValue("");
			} else if (date[0] && !date[1] && departureField?.value && !endField?.value) {
				// if departure date is selected but not the return date
				endHelpers.setValue(date[0]);
			} else if (date[0] && departureField?.value && endField.value) {
				// if both departure and return dates are selected
				departureHelpers.setValue(date[0]);
				endHelpers.setValue("");
			}

			// set the return date if it is selected and not yet set
			if (date[1] && !endField?.value) {
				endHelpers.setValue(date[1]);
			}
			// clear errors if both dates are selected
			if (date[0] && date[1] && (departureMeta.error || endMeta.error)) {
				departureHelpers.setError();
				endHelpers.setError();
			}

			if (state.isDateCleaned.departure) {
				setState(prevState => ({
					...prevState,
					isDateCleaned: {
						...prevState.isDateCleaned,
						departure: false,
					},
				}));
			}
			if (state.isDateCleaned.end) {
				setState(prevState => ({
					...prevState,
					isDateCleaned: {
						...prevState.isDateCleaned,
						end: false,
					},
				}));
			}

			// force calendar update by changing the key when dates is selected
			if (
				(date[0] && date[1]) ||
				(date[0] && !date[1] && departureField?.value && !endField?.value)
			) {
				setState(prevState => ({
					...prevState,
					calendarKey: prevState.calendarKey + 1,
				}));
			}
		},
		[
			departureHelpers,
			endHelpers,
			endField?.value,
			departureField?.value,
			departureMeta.error,
			endMeta.error,
			state.isDateCleaned.departure,
			state.isDateCleaned.end,
		]
	);

	const isDepartureDateTouched = Boolean(
		(departureMeta.touched && !departureMeta.error) ||
			state.isDateClicked.departure ||
			departureField.value
	);

	const isEndDateTouched = Boolean(
		(endMeta.touched && !endMeta.error) || state.isDateClicked.end || endField.value
	);

	const inputClassNameDeparture = classNames(
		"date-calendar-input date-calendar-input__departure",
		className,
		{
			"date-calendar-input__departure--opened":
				open &&
				((departureMeta.touched && !departureMeta.error) || state.isDateClicked.departure),
			"date-calendar-input__departure--touched": isDepartureDateTouched,
			"date-calendar-input__departure--error": departureMeta.touched && departureMeta.error,
		}
	);

	const inputClassNameReturn = classNames(
		"date-calendar-input date-calendar-input__return ",
		className,
		{
			"date-calendar-input__return--opened":
				open && ((endMeta.touched && !endMeta.error) || state.isDateClicked.end),
			"date-calendar-input__return--touched": isEndDateTouched,
			"date-calendar-input__return--error": endMeta.touched && endMeta.error,
		}
	);

	const minDate = departureDateMin && new Date(departureDateMin);
	const maxDate = departureDateMax && new Date(departureDateMax);

	const handleCleanDate = useCallback(
		type => {
			const helpers = type === DATE_INPUT_TYPE.DEPARTURE ? departureHelpers : endHelpers;
			helpers.setValue("");

			if (type === DATE_INPUT_TYPE.DEPARTURE) {
				helpers.setTouched(false);
				endHelpers.setValue("");
				endHelpers.setTouched(false);
				setState(prevState => ({
					...prevState,
					isDateCleaned: {
						...prevState.isDateCleaned,
						departure: true,
					},
				}));
			} else {
				setState(prevState => ({
					...prevState,
					isDateCleaned: {
						...prevState.isDateCleaned,
						end: true,
					},
				}));
			}
		},
		[departureHelpers, endHelpers]
	);

	const handleActiveStartDateChange = useCallback(({ activeStartDate, action }) => {
		if (action === "next" || action === "prev") {
			setState(prevState => ({
				...prevState,
				startDate: new Date(activeStartDate),
			}));
		}
	}, []);

	const handleDateClick = (
		isOtherDateClicked,
		setCurrentDateClicked,
		setOtherDateClicked,
		otherHelpers
	) => {
		setCurrentDateClicked(prev => !prev);
		if (isOtherDateClicked) {
			setOtherDateClicked(false);
			otherHelpers.setTouched(false);
		}
	};

	const onDepartureDateClick = useCallback(() => {
		//When we clean departureDate the div is also clicked so to avoid the double call we check isDepartureDateCleaned
		if (!state.isDateCleaned.departure) {
			handleDateClick(
				state.isDateClicked.end,
				clicked =>
					setState(prevState => ({
						...prevState,
						isDateClicked: {
							...prevState.isDateClicked,
							departure: clicked,
						},
					})),
				clicked =>
					setState(prevState => ({
						...prevState,
						isDateClicked: {
							...prevState.isDateClicked,
							end: clicked,
						},
					})),
				endHelpers
			);
		}
	}, [state.isDateCleaned.departure, state.isDateClicked.end, handleDateClick, endHelpers]);

	const onEndDateClick = useCallback(() => {
		//When we clean endDate the div is also clicked so to avoid the double call we check isEndDateCleaned
		if (!state.isDateCleaned.end) {
			handleDateClick(
				state.isDateClicked.departure,
				clicked =>
					setState(prevState => ({
						...prevState,
						isDateClicked: {
							...prevState.isDateClicked,
							end: clicked,
						},
					})),
				clicked =>
					setState(prevState => ({
						...prevState,
						isDateClicked: {
							...prevState.isDateClicked,
							departure: clicked,
						},
					})),
				departureHelpers
			);
		}
	}, [state.isDateCleaned.end, state.isDateClicked.departure, handleDateClick, departureHelpers]);

	const onDepartureDateBlur = useCallback(() => {
		if (isDepartureDateTouched) {
			setState(prevState => ({
				...prevState,
				isDateClicked: {
					...prevState.isDateClicked,
					departure: false,
				},
			}));
		}
		departureHelpers.setTouched(false);
		if (state.isDateCleaned.departure) {
			setState(prevState => ({
				...prevState,
				isDateCleaned: {
					...prevState.isDateCleaned,
					departure: false,
				},
			}));
		}
	}, [isDepartureDateTouched, state.isDateCleaned.departure, departureHelpers]);

	const onEndDateBlur = useCallback(() => {
		if (isEndDateTouched) {
			setState(prevState => ({
				...prevState,
				isDateClicked: {
					...prevState.isDateClicked,
					end: false,
				},
			}));
		}
		endHelpers.setTouched(false);
		if (state.isDateCleaned.end) {
			setState(prevState => ({
				...prevState,
				isDateCleaned: {
					...prevState.isDateCleaned,
					end: false,
				},
			}));
		}
		onDepartureDateBlur();
	}, [isEndDateTouched, state.isDateCleaned.end, endHelpers, onDepartureDateBlur]);

	const { context, x, y, strategy, refs } = useFloating({
		placement: "bottom",
		whileElementsMounted: autoUpdate,
		open,
		onOpenChange: open => {
			// clear date if only departure date is selected when closing calendar
			if (departureField.value && !endField.value) {
				departureHelpers.setValue("");
				departureHelpers.setTouched(false);
				endHelpers.setTouched(false);
			}
			setOpen(open);
		},
		middleware: [offset(), shift()],
	});

	useEffect(() => {
		if (!isSubmitting) {
			if (
				departureField?.value &&
				!endField?.value &&
				(isEndDateTouched || isDepartureDateTouched)
			) {
				setOpen(true);
			} else if (
				!departureField.value &&
				!endField.value &&
				(isDepartureDateTouched || (isEndDateTouched && !endMeta.error))
			) {
				setOpen(true);
			}
		}
	}, [
		departureField.value,
		endField.value,
		isEndDateTouched,
		isDepartureDateTouched,
		endMeta.error,
		departureMeta.error,
		isSubmitting,
	]);

	const dismiss = useDismiss(context);
	const click = useClick(context);
	// Gère le click et le focus par clavier
	const focus = useFocus(context, {
		keyboardOnly: false,
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click, focus]);
	const floatingElementRef = useRef(null);

	const handleScrollIntoView = useCallback(() => {
		if (floatingElementRef?.current) {
			const { top } = floatingElementRef.current.getBoundingClientRect();
			const elementPosition = top + window.scrollY - 72;
			window.scrollTo({
				top: elementPosition,
				behavior: "smooth",
			});
		}
	}, [floatingElementRef?.current]);

	useEffect(() => {
		const handleScroll = () => {
			if (floatingElementRef.current) {
				const { top, height } = floatingElementRef.current.getBoundingClientRect();
				const windowHeight = window.innerHeight;

				const calendarMidpoint = top + height / 2;

				const isHalfCalendarVisible =
					calendarMidpoint > 0 && calendarMidpoint < windowHeight;

				if (!isHalfCalendarVisible && open) {
					if (
						(departureField.value && !endField.value) ||
						(!departureField.value && !endField.value)
					) {
						departureHelpers.setValue("");
						departureHelpers.setTouched(false);
						endHelpers.setTouched(false);
						setState(prevState => ({
							...prevState,
							isDateClicked: {
								end: false,
								departure: false,
							},
						}));
					} else if (departureField.value && endField.value) {
						onEndDateBlur();
					}
					setOpen(false);
				}
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [
		open,
		onEndDateBlur,
		departureField.value,
		endHelpers,
		departureHelpers,
		endField.value,
		floatingElementRef?.current,
	]);

	useLayoutEffect(() => {
		updateFloatingPositionReference(refs);

		// Use requestAnimationFrame to ensure rendering is complete before measuring the position
		if (open && floatingElementRef?.current) {
			requestAnimationFrame(() => {
				handleScrollIntoView();
			});
		}
	}, [refs, updateFloatingPositionReference, open, handleScrollIntoView]);

	const renderTileContent = useCallback(
		({ date, view }) => {
			if (view !== "month") {
				return null;
			}

			const isDepartureDate =
				departureField?.value && isSameDay(date, new Date(departureField.value));
			const isEndDate = endField?.value && isSameDay(date, new Date(endField.value));

			if (isDepartureDate || isEndDate) {
				return <IconCalendarFlightTakeOff />;
			}
			return null;
		},
		[departureField?.value, endField?.value]
	);

	return (
		<>
			<div
				ref={refs.setReference}
				className="date-calendar-input__container"
				data-testid={dataTestId}
				tabIndex="0"
				{...getReferenceProps()}
			>
				<div
					className={`${inputClassNameDeparture} sdp-search-form__field-dates-departure`}
					name="departureDate"
					data-testid={`${dataTestId}-departure`}
					onClick={onDepartureDateClick}
					tabIndex="0" // Rendre le div focalisable
					onBlur={onDepartureDateBlur}
					ref={departureDateRef}
				>
					{!loading ? (
						<>
							<label htmlFor={id} className="date-calendar-input__label">
								{isDepartureDateTouched ? (
									<Typography variant={TYPOGRAPHY_VARIANTS.XSMALL} isBold>
										<FormattedMessage id="sdp.search.departure.date.side.panel.title" />
									</Typography>
								) : (
									<Typography variant={TYPOGRAPHY_VARIANTS.REGULAR}>
										<FormattedMessage id="sdp.search.departure.date.side.panel.title" />
									</Typography>
								)}
							</label>
							<div className="date-calendar-input__input">
								{departureField.value && (
									<FormattedDate
										value={departureField.value}
										day="2-digit"
										month="2-digit"
										year="numeric"
									/>
								)}

								{open && departureField.value && (
									<div
										className="date-calendar-input__close_icon"
										onClick={() => handleCleanDate(DATE_INPUT_TYPE.DEPARTURE)}
									>
										<IconCloseFull width={17} height={17} />
									</div>
								)}
							</div>
						</>
					) : (
						<div className="date-calendar-input__loader">
							<LoaderBar height={10} width={"70%"} />
							<LoaderBar height={10} width={"90%"} />
						</div>
					)}
				</div>
				<div
					className={`${inputClassNameReturn} sdp-search-form__field-dates-return`}
					name="endDate"
					data-testid={`${dataTestId}-return`}
					onClick={onEndDateClick}
					onBlur={onEndDateBlur}
					tabIndex="1"
					ref={endDateRef}
				>
					{!loading ? (
						<>
							<label htmlFor={id} className="date-calendar-input__label">
								{isEndDateTouched ? (
									<Typography variant={TYPOGRAPHY_VARIANTS.XSMALL} isBold>
										<FormattedMessage id="sdp.search.destination.date.side.panel.title" />
									</Typography>
								) : (
									<Typography variant={TYPOGRAPHY_VARIANTS.REGULAR}>
										<FormattedMessage id="sdp.search.destination.date.side.panel.title" />
									</Typography>
								)}
							</label>
							<div className="date-calendar-input__input">
								{endField.value && (
									<FormattedDate
										value={endField.value}
										day="2-digit"
										month="2-digit"
										year="numeric"
									/>
								)}

								{open && endField.value && (
									<div
										className="date-calendar-input__close_icon"
										onClick={() => handleCleanDate(DATE_INPUT_TYPE.END)}
									>
										<IconCloseFull width={17} height={17} />
									</div>
								)}
							</div>
						</>
					) : (
						<div className="date-calendar-input__loader">
							<LoaderBar height={10} width={"70%"} />
							<LoaderBar height={10} width={"90%"} />
						</div>
					)}
				</div>
			</div>
			{!loading && (
				<FloatingPortal preserveTabOrder={false}>
					{open && (
						<div
							ref={node => {
								refs.setFloating(node);
								floatingElementRef.current = node;
							}}
							tabIndex={-1}
							className="date-calendar-input__popover"
							{...getFloatingProps({
								style: {
									position: strategy,
									left: x + (popperOffset[0] ?? 0),
									top: y + (popperOffset[1] ?? 0),
									width: popoverWidth,
								},
							})}
						>
							<>
								<Calendar
									key={state.calendarKey}
									activeStartDate={state.startDate}
									onActiveStartDateChange={handleActiveStartDateChange}
									prevLabel={<IconArrowLeftCalendar />}
									nextLabel={<IconArrowRightCalendar />}
									className="date-calendar-input__calendar"
									locale={shop}
									onChange={handleDateSelect}
									maxDate={maxDate}
									minDate={minDate}
									value={[departureField?.value, endField?.value || null]}
									showDoubleView
									selectRange
									allowPartialRange
									defaultActiveStartDate={departureField?.value || minDate}
									onClickDay={sendTagOnPriceCalendarDateClicked}
									tileClassName={getTileClassname}
									tileDisabled={({ date }) => checkDateAvailability(date)}
									tileContent={renderTileContent}
								/>
								<div className="date-calendar-input__calendar-footer">
									<Typography
										variant={TYPOGRAPHY_VARIANTS.LARGE}
										className="date-calendar-input__calendar-footer-label"
									>
										{!isCalendarDisabled &&
											(!departureField.value ? (
												<FormattedMessage id="sdp.calendar.footer.select.departure.date.label" />
											) : (
												!endField.value && (
													<FormattedMessage id="sdp.calendar.footer.select.end.date.label" />
												)
											))}
									</Typography>
									{(!endField.value ||
										(endField.value && departureField.value)) && (
										<Button
											variant="primary"
											className="date-calendar-input__calendar-footer-button"
											data-testid="date-calendar-input-footer-button"
											onClick={() => setOpen(false)}
											disabled={!endField.value}
										>
											<FormattedMessage id="sdp.search.departure.date.side.panel.cta" />
										</Button>
									)}
								</div>

								{open && isCalendarDisabled && calendarDisabledView}
							</>
						</div>
					)}
				</FloatingPortal>
			)}
		</>
	);
};

DateCalendarInput.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	departureDateMin: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	departureDateMax: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	icon: PropTypes.element,
	checkDateAvailability: PropTypes.func,
	popperOffset: PropTypes.array,
	isCalendarDisabled: PropTypes.bool,
	updateFloatingPositionReference: PropTypes.func,
	calendarDisabledView: PropTypes.element,
	popoverWidth: PropTypes.number,
	departureDateRef: PropTypes.object,
	endDateRef: PropTypes.object,
	loading: PropTypes.bool,
	name: PropTypes.string,
	"data-testid": PropTypes.string,
};

export default memo(DateCalendarInput);
