import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DateCalendarInput from "app/pages/SmartDP/Search/DateCalendarInput/DateCalendarInput";
import { fetchSDPAvailableDepartureDate } from "app/pages/SmartDP/smartDPActionCreators";
import { useCallback, useContext, useEffect, useState } from "react";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import min from "lodash/min";
import max from "lodash/max";
import maxBy from "lodash/maxBy";
import { addDays, format, isWithinInterval } from "date-fns";
import { useField, useFormikContext } from "formik";
import { SDP_ROTATION_DATE_FORMAT } from "app/constants";
import add from "date-fns/add";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import DateCalendarDrawerInput from "app/pages/SmartDP/Search/DateCalendarInput/DateCalendarDrawerInput";

const SmartDPDateCalendarContainer = props => {
	const {
		uri,
		fetchSDPAvailableDepartureDate,
		updateFloatingPositionReference = () => {},
		handleDayChange = () => {},
		isStopover,
		popperOffset = [],
	} = props;
	const [field] = useField(props);

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;

	const selectedDepartureDate = field.value?.departureDate;
	const selectedEndDate = field.value?.endDate;

	const { setFieldValue, setFieldTouched } = useFormikContext();

	const [durationByAvailableDate, setDurationByAvailableDate] = useState({});

	const availableDates = Object.keys(durationByAvailableDate);
	const today = new Date();

	const stopoverCalendarMinDate = format(addDays(today, 2), SDP_ROTATION_DATE_FORMAT);
	const stopoverCalendarMaxDate = format(addDays(today, 340), SDP_ROTATION_DATE_FORMAT);
	const minAvailableDate = availableDates.length > 0 && min(availableDates);
	const maxAvailableDate = availableDates.length > 0 && max(availableDates);
	const calendarMinDate = isStopover ? stopoverCalendarMinDate : minAvailableDate;
	let calendarMaxDate = isStopover ? stopoverCalendarMaxDate : maxAvailableDate;

	if (selectedDepartureDate && !isEmpty(durationByAvailableDate) && !isStopover) {
		// Pour afficher le mois de fin du calendrier afin que le user puisse sélectionner
		// la date de fin la plus lointaine possible pour la date de départ sélectionnée
		// Cela permet aussi de ne pas afficher les mois non utiles pour la sélection de la date de retour
		const formattedSelectedDepartureDate = format(
			selectedDepartureDate,
			SDP_ROTATION_DATE_FORMAT
		);

		// update duration from selected departureDate
		const durationsOfSelectedDepartureDate =
			durationByAvailableDate[formattedSelectedDepartureDate];

		if (!selectedEndDate && durationsOfSelectedDepartureDate) {
			const durationMax = maxBy(
				durationsOfSelectedDepartureDate,
				duration => duration?.duration
			);

			calendarMaxDate = add(selectedDepartureDate, {
				days: durationMax?.duration,
			});
		} else {
			const availableDates = Object.keys(durationByAvailableDate);
			calendarMaxDate = max(availableDates);
		}
	}

	if (selectedDepartureDate && isStopover && !selectedEndDate) {
		calendarMaxDate = format(addDays(today, 350), SDP_ROTATION_DATE_FORMAT);
	}

	// TODO move outside of the component and unit test it
	/**
	 * return an object with departure date as key and an array of [{duration, ed}] as value
	 * {
	 *     "2022-03-21": [{duration: 1, ed: 169873457983}, {duration: 2, ed: 169873457983}],
	 *     "2022-03-22": [{duration: 1, ed: 169873409809}, {duration: 2, ed: 169873499877}]
	 * }
	 */
	const getDurationByDate = useCallback(datesByDurations => {
		const durationByDate = {};
		if (isArray(datesByDurations)) {
			datesByDurations.forEach(datesByDuration => {
				// eslint-disable-next-line no-unused-expressions
				datesByDuration?.prices?.forEach(price => {
					const departureDate = format(new Date(price.dd), SDP_ROTATION_DATE_FORMAT);
					const endDate =
						price.ed && format(new Date(price.ed), SDP_ROTATION_DATE_FORMAT);
					const dataForDepartureDate = {
						duration: datesByDuration.value,
						ed: endDate,
					};

					if (durationByDate[departureDate]) {
						durationByDate[departureDate].push(dataForDepartureDate);
					} else {
						durationByDate[departureDate] = [dataForDepartureDate];
					}
				});
			});
		}
		return durationByDate;
	}, []);

	const isCalendarDisabled = isEmpty(durationByAvailableDate);

	const clearTravelDatesFields = () => {
		setFieldValue("travelDates.departureDate", undefined);
		setFieldValue("travelDates.endDate", undefined);
		setFieldValue("duration", undefined);
		setFieldTouched("travelDates", false);
	};

	// get durations when the value of departureDate change
	// and verify if selectedDepartureDate is available
	useEffect(() => {
		if (selectedDepartureDate && !isEmpty(durationByAvailableDate)) {
			const formattedSelectedDepartureDate = format(
				selectedDepartureDate,
				SDP_ROTATION_DATE_FORMAT
			);

			// update duration from selected departureDate
			const durationsOfSelectedDepartureDate =
				durationByAvailableDate[formattedSelectedDepartureDate];

			handleDayChange({
				durationsOfSelectedDepartureDate,
				selectedEndDate,
				selectedDepartureDate,
			});

			// check is selected departure date is available
			const filteredDurationByAvailableDate = Object.keys(durationByAvailableDate).filter(
				date => {
					return isWithinInterval(new Date(date), {
						start: new Date(calendarMinDate),
						end: new Date(calendarMaxDate),
					});
				}
			);

			// if not, clear departure date, end Date and duration fields
			if (!filteredDurationByAvailableDate.includes(formattedSelectedDepartureDate)) {
				clearTravelDatesFields();
			}

			if (selectedEndDate && !isStopover) {
				const formattedSelectedEndDate =
					selectedEndDate && format(selectedEndDate, SDP_ROTATION_DATE_FORMAT);

				// check if selectedEndDate is available
				const dataOfSelectedDepartureDateMatchingSelectedEndDate = durationsOfSelectedDepartureDate?.find(
					data => {
						return data.ed === formattedSelectedEndDate;
					}
				);
				// if not clear departure date, end Date and duration fields
				if (!dataOfSelectedDepartureDateMatchingSelectedEndDate) {
					clearTravelDatesFields();
				}
			}
		} else if (!selectedDepartureDate && !isEmpty(durationByAvailableDate)) {
			setFieldValue("duration", undefined);
		}
	}, [durationByAvailableDate, selectedDepartureDate, selectedEndDate, isStopover]);

	useEffect(() => {
		if (uri) {
			fetchSDPAvailableDepartureDate(uri).then(response => {
				const responseData = response?.data;

				// console.log("**** responseData", responseData);

				if (responseData) {
					const durationsByDate = getDurationByDate(responseData);

					if (!isEmpty(durationsByDate)) {
						setDurationByAvailableDate(durationsByDate);
					} else {
						setDurationByAvailableDate({});
						clearTravelDatesFields();
					}
				}
			});
		}
	}, [uri]);

	const checkDateAvailability = useCallback(
		date => {
			if (!date || isEmpty(durationByAvailableDate)) {
				return true;
			}

			const currentDateKey = format(new Date(date), SDP_ROTATION_DATE_FORMAT);

			if (selectedDepartureDate && !selectedEndDate) {
				//For the STOPOVER website type
				if (isStopover) {
					const minDate = addDays(new Date(selectedDepartureDate), 3).getTime();
					const maxDate = addDays(new Date(), 350).getTime();

					// Convert the date to a timestamp for easy comparison
					const dateToCheck = new Date(date).getTime();

					// Enable the date if it is between the selected departure date + 3 days and today's date + 350 days
					if (dateToCheck >= minDate && dateToCheck <= maxDate) {
						return false;
					}
					return true;
				}

				const formattedSelectedDepartureDateKey = format(
					new Date(selectedDepartureDate),
					SDP_ROTATION_DATE_FORMAT
				);
				const availableData =
					durationByAvailableDate[formattedSelectedDepartureDateKey] || [];

				return !availableData.some(item => item.ed === currentDateKey);
			}

			const availableDates = Object.keys(durationByAvailableDate);
			return !availableDates.includes(currentDateKey);
		},
		[durationByAvailableDate, selectedDepartureDate, selectedEndDate, isStopover]
	);

	const handleCalendarClose = useCallback(() => {
		if (selectedDepartureDate && !selectedEndDate) {
			clearTravelDatesFields();
		}
	}, [selectedDepartureDate, selectedEndDate]);

	return isMobile ? (
		<DateCalendarDrawerInput
			{...props}
			departureDateMin={calendarMinDate}
			departureDateMax={calendarMaxDate}
			checkDateAvailability={checkDateAvailability}
			onClose={handleCalendarClose}
			handleDayChange={handleDayChange}
			isCalendarDisabled={isCalendarDisabled}
			popperOffset={popperOffset}
		/>
	) : (
		<DateCalendarInput
			{...props}
			departureDateMin={calendarMinDate}
			departureDateMax={calendarMaxDate}
			updateFloatingPositionReference={updateFloatingPositionReference}
			checkDateAvailability={checkDateAvailability}
			handleDayChange={handleDayChange}
			isCalendarDisabled={isCalendarDisabled}
			popperOffset={popperOffset}
		/>
	);
};

SmartDPDateCalendarContainer.propTypes = {
	uri: PropTypes.string,
	updateFloatingPositionReference: PropTypes.func,
	fetchSDPAvailableDepartureDate: PropTypes.func,
	handleDayChange: PropTypes.func,
	isStopover: PropTypes.bool,
	popperOffset: PropTypes.array,
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators(
		{
			fetchSDPAvailableDepartureDate,
		},
		dispatch
	);
};

export default connect(
	null,
	mapDispatchToProps
)(SmartDPDateCalendarContainer);
